import React, { useState, useRef } from 'react';
import './contact.css';
import { HiOutlineMail } from 'react-icons/hi';
import { RiMessengerFill } from 'react-icons/ri';
import { AiOutlinePhone } from 'react-icons/ai';
import emailjs from 'emailjs-com';
import corner from '../../assets/corner_img.png';

const Contact = () => {
  const form = useRef();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      await emailjs.sendForm('service_mptmuyd', 'template_86c5b76', form.current, 'NNp8gKejwWg3IZ36_');
      e.target.reset();
      setIsFormSubmitted(true);
    } catch (error) {
      console.error('Błąd podczas wysyłania wiadomości:', error);
    }
  };

  return (
    <section id='contact'>
      <h5>Porozmawiajmy</h5>
      <h2>Kontakt</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <HiOutlineMail className='contact__option-icon' />
            <h4>Email</h4>
            <h5>gratysdesign@gmail.com</h5>
            <a href="mailto:gratysdesign@gmail.com" target="_blank" rel="noreferrer">Wyślij wiadomość</a>
          </article>
          <article className="contact__option">
            <RiMessengerFill className='contact__option-icon' />
            <h4>Messenger</h4>
            <h5>Filip</h5>
            <a href="https://m.me/filip.gratys" target="_blank" rel="noreferrer">Wyślij wiadomość</a>
          </article>
          <article className="contact__option">
            <AiOutlinePhone className='contact__option-icon' />
            <h4>Telefon</h4>
            <h5>880 742 721</h5>
          </article>
        </div>

        <form ref={form} onSubmit={sendEmail}>
          {isFormSubmitted ? (
            <div className="confirmation-message">
              Dziękuję za wiadomość, została przekazana na moją skrzynkę. Odpowiem po zapoznaniu się :)
            </div>
          ) : (
            <div>
              <input type="text" name='name' placeholder='Twoje imię' required />
              <input type="email" name='email' placeholder='Twój mail' required />
              <textarea name="message" rows="7" placeholder='Twoja wiadomość' required></textarea>
              <button type='submit' className='btn btn-primary'>Wyślij wiadomość</button>
              <p className="rodo-info">
                Administratorem Twoich danych osobowych jest Gratys.pl. Dane z formularza będą przetwarzane zgodnie z art. 6 ust. 1 lit. b RODO wyłącznie w celu udzielenia odpowiedzi na Twoje zapytanie i zostaną usunięte po zakończeniu korespondencji. Podanie danych jest dobrowolne, ale konieczne do kontaktu. Masz prawo do dostępu do swoich danych i ich usunięcia.
              </p>
            </div>
          )}
        </form>
      </div>
      <div className="content-container">
        <img src={corner} alt="Tu powinno być zdjęcie" className="corner-image" />
      </div>
    </section>
  );
}

export default Contact;
